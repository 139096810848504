<template>
  <v-container>
    <v-row>
      <v-breadcrumbs large :items="items_nav">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-card class="ma-4">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
        <v-btn
          class="mx-2"
          @click="saveOrdem()">
          SALVA ORDEM
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="dataTable"
        :search="search"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        disable-pagination
      >

        
        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            @change="changeOrdem(props.items)"
          >
            <tr v-for="(item, index) in props.items" :key="index">
              <td><v-icon large style="cursor:pointer;">mdi-drag</v-icon></td>
              <td><v-row>
            <img v-if='item.banners.length > 0' :src="item.banners[0]" height="80" width="160" class="my-8 mx-4"/>
            <h4 v-if='item.banners.length == 0' class="my-8 mx-8">SEM IMAGEM</h4>  
          </v-row></td>
              <td>{{item.titulo}}</td>
              <td>{{ getCategoria(item.categoria_id) }}</td>
              <td>
                <v-row>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2  yellow darken-3"     
                :to="{name: 'dashboard-inspiracao', params: {id: item.id}}"        
              >
                <v-icon small dark>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2  red darken-3"
                @click="deleteItem(item)"
              >
                <v-icon small dark>mdi-delete</v-icon>
              </v-btn>
          </v-row>
              </td>
            </tr>
          </draggable>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="getDataFromApi">Reiniciar</v-btn>
        </template>
      </v-data-table>  
    </v-card>

    <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Realizando operação por favor aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Excluir
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="col">
              <b>Inspiração:</b><br />
              {{ itemSelected.titulo }}
            </div>
          </v-row>
          <br>
          <p>
            Deseja realmente excluir essa inspiração? Essa operação não poderá ser desfeita.
          </p>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-row
            align="center"
            justify="end"
            class="my-4"
          >
            <v-btn
            class="mx-4"
              color="red darken-3 btn-modal"
              @click="dialogDelete = false"
            >
              Cancelar
            </v-btn>
            <v-btn class="mx-4" color="green darken-1 btn-modal" @click="excluirInspiracao()">
              Continuar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMessage" width="480">
        <v-card class="mx-auto"  dark>
        <v-card-title>
            <span class="title font-weight-light">{{ message.title }}</span>
        </v-card-title>
        <v-card-text class="headline font-weight-bold">
            {{ message.body }}
        </v-card-text>
        </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import database from "@/services/database";
import store from '@/store'
import draggable from "vuedraggable";

export default {
  name: "Inspiracoes",
  components: {
    draggable,
  },
  data() {
    return {
      items_nav: [
        {
          text: "Sistema",
          disabled: false,
        },
        {
          text: "Inspirações",
          disabled: true,
        },
        {
          text: "Listagem",
          disabled: true,
        }
      ],
      loading: true,
      search: "",
      singleSelect: false,
      selected: [],
      itemSelected: {
        titulo: ""
      },
      message: {
        title: "",
        body: ""
      },
      dialogMessage: false,
      dialog: false,
      dialogProgress: false,
      dialogEdit: false,
      dialogAddress: false,
      dialogDelete: false,
      address: [],
      registers: [],
      headers: [
        { text: "", value: "drag" },
        { text: "Imagem", value: "banners" },
        { text: "Título", value: "titulo" },
        { text: "Categoria", value: "categoria_id" },
        /* { text: "Telefone", value: "telefone" },
        { text: "Tags", value: "tags" }, */
        { text: "Ações", value: "actions" },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi: async function() {
      this.loading = true;
      var data = await database.getAllInspiracoes();
      this.dataTable = data;
      this.loading = false;
    },
    getCategoria: function(id) {
        return store.getters.getCategoriaById(id).nome;
    },
    deleteItem: async function(item) {
      this.itemSelected = item;
      this.dialogDelete = true;
    },
    changeOrdem: async function(data) {
      this.loading = true;
      this.dataTable = data;
      this.loading = false;
    },
    saveOrdem: async function() {
      this.loading = true;
      var order = [];
      await this.dataTable.forEach((item, index) => {
        order.push(item.id);
      });
      var result = await database.updateInspiracoesOrdem(order);
      this.loading = false;
      if (result) {
        this.message.title = "Operação realizada";
        this.message.body = "Posições salvas com sucesso!";
        this.dialogMessage = true;
      } else {
        this.message.title = "Operação falhou";
        this.message.body = "Verifique sua conexão e tente novamente!";
        this.dialogMessage = true;
      }
    },
    excluirInspiracao: async function() {
      this.dialogDelete = false;
      this.dialogProgress = true;
      let result = await database.deleteInspiracaoById(this.itemSelected.id);
      this.dialogDelete = false;
      if (result) {
        this.dialogProgress = false;
        this.getDataFromApi();
      } else {
        this.dialogProgress = false;
        this.showSnackbarError = true;
      }
    }
  },
};
</script>
